<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>My classrooms</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ My classrooms
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">

            <div class="">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" md="5">
                    <v-text-field class="form-control" v-model="search.name"
                                  label="Search by class name" outlined clearable
                                  v-on:keyup.enter="getAllStudentAcademicClasses()" dense>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-select
                        outlined
                        dense :loading="gradeLoading"
                        :items="grades"
                        item-text="grade_title"
                        item-value="grade_id"
                        label="Semester/Level"
                        clearable
                        v-model="search.grade_id"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" md="3">
                    <v-btn :loading="loading" @click.prevent="getAllStudentAcademicClasses()"
                           class="btn btn-primary w-35 float-right" dark>
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3 warp-column">
                  <th class="px-3 wrap-column">Class</th>
                  <th class="px-3 wrap-column">Academic info</th>
                  <th class="pr-3 text-center">Action</th>
                </tr>
                </thead>
                <template>
                  <tr v-for="(academicClass, index) of academic_classes" :key="index">
                    <td class="px-3">
                      <span class="font-weight-bold font-weight-black" style="font-size: 18px;">
                        {{ academicClass.title }}
                      </span>
                      <div>
                        <span class="font-weight-medium">{{ academicClass.unique_no ? academicClass.unique_no : "" }} </span> |
                        <span class="font-weight-medium">{{ academicClass.shift.toUpperCase() }}</span>
                      </div>
                    </td>
                    <td class="px-3">
                      <span class="font-weight-medium">{{academicClass.program_short_title }}</span> |
                      <span class="font-weight-medium">{{ academicClass.grade_title }}</span> |
                      <span class="font-weight-medium">{{ academicClass.academic_year_title }}</span> <br/>
                    </td>
                    <td class="pr-3 text-center">
                      <b-dropdown size="sm" variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret right no-flip >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                            ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text tag="div" class="navi-item" v-if="academicClass.has_timetable">
                            <a
                                href="#"
                                class="navi-link"
                                @click="viewTimetablePdf(academicClass.id)"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Preview timetable </span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="!academicClass.has_timetable">
                            <a href="#" class="navi-link">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text">
                                <strike>Preview timetable </strike>
                              </span>
                            </a>
                          </b-dropdown-text>


                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>

                  </tr>
                  <tr v-if="academic_classes.length == 0">
                    <td class="px-3 text-center" colspan="4">
                      <strong>No data available to display.</strong>
                    </td>
                  </tr>
                </template>
              </table>

            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import AcademicClassStudentService from "@/core/services/academic-class/class-student/AcademicClassStudentService";
import {API_URL} from "@/core/config";
import StudentSettingService from "@/core/services/user/student/StudentSettingService";

const studentSetting=new StudentSettingService();
const academicClassStudent=new AcademicClassStudentService();
export default {
  data(){
    return{
      loading:false,
      page: null,
      total: null,
      perPage: null,
      academic_classes: [],
      grades:[],
      gradeLoading:false,
      search:{

      },
    }
  },
  methods:{
    getAllStudentAcademicClasses(){
      this.loading=true;
      academicClassStudent
          .getAllStudentAcademicClass(this.search)
          .then(response => {
            this.academic_classes=response.data.classess;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading = false;
          });
    },

    viewTimetablePdf(classroomId) {
      window.open(
          API_URL + "user/student/academic-class-timetable/"+classroomId+"/preview",
          "_blank"
      );
    },
    getGrades() {
      this.search.student_grade_id=null;
      this.gradeLoading=true;
      studentSetting
          .getAllStudentGrades()
          .then(response => {
            this.grades = response.data.data;
            if(this.grades.length > 0){
              this.search.grade_id=this.grades[0].grade_id;
              this.getAllStudentAcademicClasses();
            }

          }).catch((error) => {

      })
          .finally(() => {
            this.gradeLoading=false;
          });
    },
  },
  mounted() {
    this.getGrades();

  }
}
</script>